.landing-page,
.packages-page {
  font-family: "Montserrat", sans-serif;
}

/* LandingPage */

.landing-page {
  position: relative;
  height: 100vh;
}

.background-image {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}

.main-heading {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 2.2em;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.2em;
  outline: none;
}

.bold-text {
  font-weight: bold;
  font-size: 2.3em;
}

.see-packages-button {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  padding: 15px 30px;
  background-color: lavender;
  color: black;
  font-size: 1.2em;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* Dessert Selections Page CSS */

.dessert-selections-page {
  text-align: center;
  padding: 20px;
}

.dessert-heading {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.2rem;
  font-size: 2.5em;
  color: black;
  margin-bottom: 20px;
}

.note {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
  background-color: lavender;
  padding: 10px;
}

.all-dessert-rentals {
  text-align: center;
  padding: 10px;
  margin-top: -50px;
}

.all-dessert-rentals h2 {
  color: black;
}

.all-dessert-rentals ul {
  list-style-type: none;
  padding: 0;
}

.all-dessert-rentals li {
  margin: 10px 0;
}

.all-dessert-rentals .price {
  font-weight: bold;
  color: green;
}

.dessert-sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.dessert-package {
  width: 350px; 
  height: 575px; 
  margin: 25px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.dessert-package h2 {
  color: black;
  background-color: lavender;
}

.dessert-package ul {
  list-style-type: none;
  padding: 0;
}

.dessert-package li {
  margin: 10px 0;
}

.dessert-package .price {
  font-weight: bold;
  color: green;
}

/* Hamburger Menu */

.hamburger-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}

.menu-icon {
  font-size: 2em;
  color: #333;
}

.menu-items {
  display: none;
  position: absolute;
  top: 50px; 
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.menu-items a {
  display: block;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.menu-items a:hover {
  background-color: #f0f0f0;
}

.hamburger-menu.open .menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* FAQ Page CSS */

.faq-page {
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.faq-container {
  max-width: 800px;
  width: 100%;
}

.faq-heading {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.2rem;
  font-size: 2.0em;
  color: black;
  margin-bottom: 50px;
  text-align: center;
}

.faq-item {
  margin-bottom: 30px;
}

.faq-item h2 {
  font-size: 1.2em;
  color: #333;
  text-align: center;
}

.faq-item p {
  font-size: 1.2em;
  color: #555;
  text-align: center;
}

/* Photos CSS */

.photos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  justify-content: center; 
}

.photos-heading {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  letter-spacing: 0.1em;
  font-size: 2.2rem;
  margin-top: 50px;
}

.photo-thumbnail {
  width: 250px;
  height: 250px; 
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.photo-thumbnail:hover {
  transform: scale(1.1);
}

.photo-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.view-button {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.photo-thumbnail:hover .view-button {
  opacity: 1;
}

.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo-modal img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.photo-modal p {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2em;
  color: white;
  margin: 8px 0;
}

.photo-modal button {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  color: black;
  font-size: 1rem;
}

/* Socials CSS */

.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
}

.social-icons-container a {
  margin: 0 14px; 
}

.social-icons-container i {
  font-size: 30px;
  color: #9480ba;
}

/* Responsive Design */

@media (max-width: 768px) {
  .main-heading {
    font-size: 1.8em;
  }

  .see-packages-button {
    top: 80%;
  }

  .packages-page {
    flex-direction: column;
    align-items: center;
  }

  .package {
    width: 90%;
    margin-right: 0;
  }
}

